import { Breadcrumbs, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import KenColors from '../../Helpers/Themes/kenColors';

const useStyles = makeStyles((theme: Theme) => ({
  mainText: {
    // fontSize: '20px !important',
    fontWeight: '600 !important',
    textDecoration: 'none',
    textTransform: 'capitalize',
    margin: '0px !important',
    padding: '0px !important',
  },
  listText: {
    textDecoration: 'none',
    textTransform: 'capitalize',
    margin: '0px !important',
    padding: '0px !important',
  },
}));

const AppBreadCrumbs: React.FunctionComponent = () => {
  const { pathname, state } = useLocation();
  const classes = useStyles();
  const navigateHistory: Array<string> = pathname.split('/').filter((items) => {
    return items && items !== '';
  });
  const navigateState: null | string = state?.breadCrumbs;
  let customPath: string = '';

  return (
    <>
      <Typography className={classes.mainText} color={KenColors.ClientBlack2} fontSize={{ xs: '12px', md: '16px', lg: '20px' }}>
        {navigateHistory[0]}
      </Typography>
      {navigateHistory.length > 1 && (
        <Breadcrumbs separator={<Typography sx={{ fontSize: { xs: '10px', md: '12px', lg: '14px' }, fontWeight: '600' }}>&gt;</Typography>} style={{ color: KenColors.ClientBlack2 }}>
          {navigateHistory.map((items, index) => {
            customPath = customPath + `;${items}`;
            const isLastIndex = index === navigateHistory.length - 1;
            const newPath = customPath.replace(/;/g, '/');
            let newItem = items.replace(/-/g, ' ')
            return index === navigateHistory.length - 1 && navigateState ? (
              <Typography key={index} className={classes.listText} color={KenColors.ClientBlack2} fontSize={{ xs: '10px', md: '12px', lg: '14px' }}>
                {navigateState}
              </Typography>
            ) : (
              <Link key={index} className={classes.listText} to={`${newPath}`}>
                <Typography sx={{ color: isLastIndex ? KenColors.ClientRoyalpurple700 : KenColors.ClientBlack1, fontWeight: isLastIndex ? '600' : '500', fontSize: { xs: '10px', md: '12px', lg: '14px' } }}>
                  {newItem}
                </Typography>
              </Link>
            );
          })}
        </Breadcrumbs>
      )}
    </>
  );
};

export default AppBreadCrumbs;
