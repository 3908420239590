import { useContext } from 'react';
import { AppFilterProps, ReactChildren, SelectOptions, ValuesOfAny } from '../interfaces/Common';
import { AuthContext } from '../Helpers/Contexts/authContext';
import { Navigate } from 'react-router-dom';
import routes from './routes';
import { getFileUrl } from './apiService';
import moment from 'moment';
import { enqueueSnackbar } from 'notistack';

export const getCombinedString: (array: Array<string>, seperator?: string) => string = (array: Array<string>, seperator?: string) => {
  let arrayString: string = '';
  let customSeperator = seperator ? seperator : ',';
  array.forEach((items: string, index: number) => {
    if (arrayString === '') {
      arrayString = `${items}${customSeperator} `;
    } else if (index === array.length - 1) {
      arrayString = arrayString + `${items}`;
    } else {
      arrayString = arrayString + `${items}${customSeperator} `;
    }
  });
  return arrayString;
};

export const splitCombinedStringintoArray = (value: string, seperator?: string) => {
  let customSeperator = seperator ? seperator : ',';
  return value.split(customSeperator);
};

export const handleFilterData: (key: string, array: Array<any>) => Array<SelectOptions> = (key: string, array: Array<any>) => {
  const set = new Set();
  const handleNull = (data: string) => {
    if (data && data !== null) {
      set.add(data);
    }
  };
  for (let i = 0; i < array?.length; i++) {
    if (array[i]) {
      const category = array[i][key] as string;
      if (category && `${category}`.includes(';')) {
        const categoryArray = category?.split(';');
        for (let j = 0; j < categoryArray.length; j++) {
          handleNull(categoryArray[j]);
        }
      } else {
        handleNull(category);
      }
    }
  }
  return [...set].map((items) => {
    return { label: items, value: items };
  }) as Array<SelectOptions>;
};

export const getYears: (start: number) => Array<SelectOptions> = (start: number) => {
  const array: Array<SelectOptions> = [];
  const date = new Date();
  for (let i = start; i <= date.getFullYear(); i++) {
    array.push({
      label: `${i}`,
      value: `${i}`,
    });
  }
  return array.reverse();
};

export const PublicRoute: React.FunctionComponent<ReactChildren> = ({ children }) => {
  const {
    state: { isAuthenticated },
  } = useContext(AuthContext);

  if (isAuthenticated) {
    return <Navigate to={routes.restaurant} />;
  }
  return children;
};

export const PrivateRoute: React.FunctionComponent<ReactChildren> = ({ children }) => {
  const {
    state: { isAuthenticated },
  } = useContext(AuthContext);

  if (!isAuthenticated) {
    return <Navigate to={routes.login} />;
  }
  return children;
};

export const AppFilterFunction: (props: AppFilterProps) => Array<any> = (props) => {
  const { filterData, data } = props;
  const filteringData = { ...filterData };
  let sampleEventData = [...data];
  for (const i in filteringData) {
    const filteredArray = [];
    for (let j = 0; j < sampleEventData.length; j++) {
      const element = sampleEventData[j];
      if (element[i] !== null) {
        if (`${element[i]}`.toLowerCase().includes(`${filteringData[i]}`.toLowerCase())) {
          filteredArray.push(element);
        }
      }
    }
    sampleEventData = [...filteredArray];
  }
  return sampleEventData;
};

export function pad(n: string, width: number, z?: string) {
  z = z || '0';
  n = n + '';
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

export const handleFiles = async (e: React.ChangeEvent<HTMLInputElement>, files: any, types?: Array<string>, size?: number) => {
  // File Check
  if (!e.target.files) {
    return { message: 'Please Upload File', success: false };
  }
  const getFile = e.target.files[0];
  const customSize = size ? size : 2000000;

  // File Type Check
  if (types) {
    const getMatch: Array<string> = types?.filter((items) => {
      return items === getFile.type;
    });
    if (getMatch.length === 0) {
      return {
        message: `Only the ${types.map((items) => {
          return `${items} `;
        })} formats are accepted`,
        success: false,
      };
    }
  }

  // File Size Check
  if (getFile.size > customSize) {
    return { message: 'File is larger than 1MB', success: false };
  }
  try {
    const data = await getFileUrl(e.target.files[0]);
    if (data.result?.success) {
      return data;
    } else {
      return { message: 'Upload Failed, Try Again !', success: false };
    }
  } catch (err) {
    return { message: 'Upload Failed, Try Again !', success: false };
  }
};

export const moneyValidator = (start: string, end: string) => {
  const salary = start === end;
  return salary ? `${start} LPA` : `${start}-${end} LPA`;
};

export const rangeValidatorPayload = (year: string) => {
  const getYears = year.split('-');
  if (getYears.length === 2) {
    return [parseInt(getYears[0]), parseInt(getYears[1])];
  } else if (getYears.length === 1) {
    return [parseInt(getYears[0]), parseInt(getYears[0])];
  } else {
    return '';
  }
};

export const uniqueArrayCreator = (array: Array<any>, key: string) => {
  return Array.from(
    new Set([
      ...array?.map((items: any) => {
        return items[key];
      }),
    ])
  );
};

export const registeredDatas = (array: Array<any>, objectKey: string) => {
  const newObject: ValuesOfAny = {};
  array?.forEach((items: any) => {
    const keyValue = items[objectKey];
    const date = moment(keyValue).format('Do MMMM YYYY');
    items['find'] = date;
    if (newObject.hasOwnProperty(date)) {
      newObject[date].push({ ...items });
    } else {
      newObject[`${date}`] = [items];
    }
  });
  return newObject;
};

export const copyFunction = (text: string) => {
  const clipboardItem = new ClipboardItem({ 'text/plain': new Blob([text], { type: 'text/plain' }) });
  navigator.clipboard
    .write([clipboardItem])
    .then(() => {
      enqueueSnackbar({ message: 'Link copied', variant: 'success' });
    })
    .catch((err) => {
      console.error(err);
    });
};

export const removeHTMLTags = (htmlString: string) => {
  return htmlString.replace(/<[^>]*>/g, '');
};


export const getUsernameFromURL = (url: string) => {
  try {
    const { hostname, pathname } = new URL(url);
    if (hostname.includes('linkedin')) {
      return pathname.split('/')[2];
    } else {
      const domainParts = hostname.split('.');
      const domain = domainParts.slice(-1).join('.');
      return `${domainParts[0]}.${domain}`;
    }
  } catch (error) {
    console.error('Invalid URL', error);
    return '';
  }
};

export const formatAmount = (amount: number) => {
  if (amount >= 1_000_000) {
    return (amount / 1_000_000).toFixed(1) + 'M';
  } else if (amount >= 1_000) {
    return (amount / 1_000).toFixed(1) + 'K';
  }
  return amount;
};


export const truncateText = (text: string, maxLength: number) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + '...';
  }
  return text;
};

export const getCode = (country: string) => {
  switch (country) {
    case "India":
      return "IN";
    case "USA":
      return "US";
    case "Singapore":
      return "SG";
    case "Middle East Asia":
      return "AE";
    default:
      return "";
  }
};