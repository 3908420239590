import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { KEY_ACCESS_TOKEN, KEY_AZURE_TOKEN, KEY_CONFIG_URL, KEY_REFRESH_TOKEN, REFRESH_URL, X_API_KEY } from '../Helpers/constant';
import { LoginData } from '../interfaces/Auth';
import { PaymentData } from '../interfaces/Common';
import { EventFormValues, RegisterEvent } from '../interfaces/Events';
import { postComplaintPayload } from '../interfaces/Survey';
import { ProfileDetailsFormValues } from '../interfaces/Profile';
import { BACKEND_URL } from '../Helpers/constant';

let appConfig: { apiBaseUrl: string; tokenUrl: string } | undefined;
export let axiosInstance: AxiosInstance;
const configUrl = KEY_CONFIG_URL;
export const getAxiosInstance = (data: { config?: { apiBaseUrl: string; tokenUrl: string } }[]) => {
  appConfig = data[0]?.config;
  axiosInstance = axios.create({
    // baseURL: 'http://localhost:3000',
    baseURL: appConfig?.apiBaseUrl,
    timeout: 15000,
  });

  axiosInstance.interceptors.request.use((req: AxiosRequestConfig): any => {
    const token = localStorage.getItem(KEY_AZURE_TOKEN);
    req.headers = req.headers || {};
    req.headers['x-api-key'] = X_API_KEY;
    req.headers['Authorization'] = `Bearer ${token}`;

    return req;
  });

  axiosInstance.interceptors.response.use(
    (resp) => {
      return resp;
    },
    async function (error) {
      if (!error.response?.config && error.response?.status !== 401) {
        return error;
      }
      return axios({
        method: 'get',
        baseURL: `${appConfig?.tokenUrl}`,
      }).then(async (response) => {
        if (localStorage.refresh_token && (error.response.status === 402 || error.response.status === 502 || error.response.status === 500)) {
          const url = REFRESH_URL;
          try {
            const res = await axios.post(
              url,
              {
                refreshToken: localStorage.refresh_token,
              },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.azure_token}`,
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                },
              }
            );
            localStorage.setItem(KEY_ACCESS_TOKEN, res?.data?.accessToken?.token);
            localStorage.setItem(KEY_REFRESH_TOKEN, res?.data?.refreshToken?.token);
            localStorage.setItem(KEY_AZURE_TOKEN, response.data.access_token);
            error.response.config.headers['Authorization'] = `Bearer ${response.data.access_token}`;
            error.response.config.headers['access-token'] = res.data.accessToken.token;
            return axios(error.response.config);
          } catch (err) {
            localStorage.clear();
            window.location.reload();
          }
        } else {
          localStorage.setItem(KEY_AZURE_TOKEN, response.data.access_token);
          error.response.config.headers['Authorization'] = `Bearer ${response.data.access_token}`;
          return axios(error.response.config);
        }
      });
    }
  );
};

export const getConfig = async () => {
  try {
    const configKey = '664c2d35a926bb001ae67409';
    const res = await axios.get(`${configUrl}/${configKey}`);
    return res && res.data ? res.data : null;
  } catch (err) {
    return err;
  }
};

export const login = async (payload:any) => {
  try {
    const res = await axiosInstance.post(`${BACKEND_URL}/auth/admin_login`,payload)
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
};

export const getRestautantDetails = async () => {
  try {
    const res = await axiosInstance.get(`${BACKEND_URL}/restaurant/get_all_restaurant`)
    return res && res.data ? res.data : res;
  } catch(error) {
    return error;
  }
}

export const getUserDetails = async () => {
  try {
    const res = await axiosInstance.get(`${BACKEND_URL}/user/get_all_user`)
    return res && res.data ? res.data : res;
  } catch(error) {
    return error;
  }
}

export const getOrderDetailsViaRestautrantId = async (restaurant_id: any) => {
  try {
    const res = await axiosInstance.get(`${BACKEND_URL}/restaurant/orders?restaurant_id=${restaurant_id}`)
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
}

export const getRestaurantItems = async (restaurant_id: any) => {
  try {
    const res = await axiosInstance.get(`${BACKEND_URL}/restaurant/get_items?restaurant_id=${restaurant_id}`)
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
}

export const getMealsDetailsViaRestautrantId = async (restaurant_id: any) => {
  try {
    const res = await axiosInstance.get(`${BACKEND_URL}/restaurant/current_item?restaurant_id=${restaurant_id}`)
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
}

export const getDeliveryExecutiveDetailsViaRestaurantId = async (restaurant_id: any) => {
  try {
    const res = await axiosInstance.get(`${BACKEND_URL}/user/get_restaurant_user?restaurant_id=${restaurant_id}`)
    return res && res.data ? res.data : res;
  } catch(error){
    return error;
  }
}

export const createRestaurantUser = async(payload: any) => {
  try {
    const res = await axiosInstance.post(`${BACKEND_URL}/user/create_user`, payload)
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
}

export const updateRestaurantStatus = async(payload: any) => {
  try {
    const res = await axiosInstance.patch(`${BACKEND_URL}/restaurant/update_restaurant`, payload)
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
}

// export const verifyOtp = async (email: string, otp: string) => {
//   try {
//     // const path = `/v1/auth/verify/otp?email=${email}&otp=${otp}`;
//     // const res = await axiosInstance.get(path);
//     const res = await axiosInstance.post('https://node-test-app-6cuykaqaya-as.a.run.app/auth/admin_login',)
//     return res && res.data ? res.data : res;
//   } catch (error) {
//     return error;
//   }
// };

export const verifyOtpReset = async (email: string, otp: string) => {
  try {
    const path = `/v1/auth/verify/otpwithContact?email=${email}&otp=${otp}`;
    const res = await axiosInstance.post(path);
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
};

export const loginSSO = async (email: string) => {
  try {
    const path = `/v1/auth/sso/user/verify?email=${email}`;
    const response = axiosInstance.get(path);
    return response;
  } catch (error) {
    return error;
  }
};

export const authLogin = async (data: LoginData) => {
  try {
    const path = `/v1/auth/login/verify`;
    const res: any = await axiosInstance.post(path, data);
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
};

export const resetPassword = async (data: LoginData) => {
  try {
    const path = `/v1/auth/login/reset`;
    const res: any = await axiosInstance.post(path, data);
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
};
export const getEvents = async (contactId: string) => {
  try {
    const path = `/v1/event/fetch/user/events?contactId=${contactId}`;
    const res: any = await axiosInstance.get(path);
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
};

export const getEvent = async (id: string) => {
  try {
    const path = `/v1/event/fetch/event/ById?eventId=${id}`;
    const res: any = await axiosInstance.get(path);
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
};

export const getFileUrl = async (data: File) => {
  try {
    const path = `/v1/event/create/publicUrl`;
    const form = new FormData();
    form.append('file', data);
    const res: any = await axiosInstance.post(path, form);
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
};

// profile

export const getProfile = async (id: string) => {
  try {
    const path = `v1/profile/get/account/info?id=${id}`;
    const res: any = await axiosInstance.get(path);
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
};
export const postEditProfile = async (payload: ProfileDetailsFormValues) => {
  try {
    const path = `/v1/profile/update/account/info`;
    const res: any = await axiosInstance.patch(path, payload);
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
};


// events
export const hostEvent = async (payload: EventFormValues) => {
  try {
    const path = `/v1/event/create/event/schedule`;
    const res: any = await axiosInstance.post(path, payload);
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
};

export const registerEvent = async (payload: RegisterEvent) => {
  try {
    const path = `/v1/event/new/registration`;
    const res: any = await axiosInstance.post(path, payload);
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
};

export const eventPayment = async (payload: PaymentData) => {
  try {
    const path = `/v1/payment/generate/payment`;
    const res: any = await axiosInstance.post(path, payload);
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
};

export const cancelEvent = async (id: string) => {
  try {
    const path = `/v1/event/cancel/registration?eventRegistrationId=${id}`;
    const res: any = await axiosInstance.delete(path);
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
};

export const getOptions = async (objectName: string, fieldName: string) => {
  try {
    const path = `/v1/helper/getDropDownValues?objectName=${objectName}&fieldName=${fieldName}`;
    const res: any = await axiosInstance.get(path);
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
};
export const getFaq = async () => {
  try {
    const path = `/v1/support/get/faq`;
    const res: any = await axiosInstance.get(path);
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
};

export const getTicketHistory = async (accountId: string) => {
  try {
    const path = `/v1/support/get/user/case?userId=${accountId}`;
    const res: any = await axiosInstance.get(path);
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
};

export const cancelService = async (id: string) => {
  try {
    const path = `/v1/support/delete/case?caseId=${id}`;
    const res: any = await axiosInstance.delete(path);
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
};

export const postComplaint = async (payload: postComplaintPayload) => {
  try {
    const path = `/v1/support/post/case`;
    const res: any = await axiosInstance.post(path, payload);
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
};

export const getJobFavorite = async (accountId: string) => {
  try {
    const path = `/v1/favourite/get/user/favorite?accountId=${accountId}`;
    const res: any = await axiosInstance.get(path);
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
};

// people
export const getInvestors = async () => {
  try {
    const path = `/v1/people/get/investors`;
    const res: any = await axiosInstance.get(path);
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
};

export const getInvestorsById = async (id: string) => {
  try {
    const path = `/v1/people/get/investors/ById?id=${id}`;
    const res: any = await axiosInstance.get(path);
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
};

// COE
export const getCOE = async () => {
  try {
    const path = `/v1/people/get/coe`;
    const res: any = await axiosInstance.get(path);
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
};

export const getCOEOfferings = async (id: string) => {
  try {
    const path = `v1/people/get/coe/offering/ById?id=${id}`;
    const res: any = await axiosInstance.get(path);
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
};


// my portfolio
export const getPortfolio = async () => {
  try {
    const path = `v1/portfolio/get/portfolio`;
    const res: any = await axiosInstance.get(path);
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
};

export const getPortfolioTeam = async (id: string) => {
  try {
    const path = `v1/portfolio/get/team?id=${id}`;
    const res: any = await axiosInstance.get(path);
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
};


export const getPortfolioKeyCustomers = async (id: string) => {
  try {
    const path = `v1/portfolio/get/client?id=${id}`;
    const res: any = await axiosInstance.get(path);
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
};

// startups
export const getStartups = async () => {
  try {
    const path = `v1/portfolio/get/startups`;
    const res: any = await axiosInstance.get(path);
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
};