import axios from 'axios';
import React, { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AppLayout from '../../Components/AppLayout';
import AuthLayout from '../../Components/AuthLayout';
import KenLoader from '../../GlobalComponents/KenLoader';
import { useAppContext } from '../../Helpers/Contexts/appContext';
import { KEY_AZURE_TOKEN } from '../../Helpers/constant';
import { getAxiosInstance, getConfig } from '../../Utils/apiService';
import { PrivateRoute, PublicRoute } from '../../Utils/helperFunctions';
import routes from '../../Utils/routes';
import { ConfigOptions } from '../../interfaces/Common';

const Login = React.lazy(() => import('../Auth/Login/index'));
const Restaurant = React.lazy(() => import('../Restaurant'));
const Users = React.lazy(() => import('../Users'));
const Orders = React.lazy(() => import('../Restaurant/Order'))
const ParticularRestaurant = React.lazy(() => import('../Restaurant/ParticularRestaurant'))

const MainLayout: React.FunctionComponent = () => {
  const {
    dispatch,
    state: { config },
  } = useAppContext();

  useEffect(() => {
    getConfig()
      .then((res: Array<ConfigOptions>) => {
        dispatch({ type: 'updateConfig', value: res[0]?.config });
        dispatch({ type: 'updateLogo', value: res[0]?.config?.Logo });
        getAxiosInstance(res);
        try {
          axios({ method: 'get', baseURL: res[0]?.config?.tokenUrl })
            .then((response: any) => {
              localStorage.setItem(KEY_AZURE_TOKEN, response.data.access_token);
            })
            .catch((err) => {
              return err;
            });
        } catch (err) {
          console.error(err);
        }
      })
      .catch((err) => {
        console.error('error in config', err);
      });
  }, []);

  return config.apiBaseUrl === '' ? (
    <KenLoader />
  ) : (
    <>
      <Routes>
        {/* Initial Route */}
        <Route path="/" element={<Navigate to={routes.login} />} />

        {/* Public Routes */}
        <Route
          path={routes.login}
          index
          element={
            <PublicRoute>
              <AuthLayout>
                <Login />
              </AuthLayout>
            </PublicRoute>
          }
        />

        <Route
          path={routes.restaurant}
          element={
            <PrivateRoute>
              <AppLayout>
                <Restaurant />
              </AppLayout>
            </PrivateRoute>
          }
        />

        <Route
          path={routes.users}
          element={
            <PrivateRoute>
              <AppLayout>
                <Users />
              </AppLayout>
            </PrivateRoute>
          }
        />

        <Route
          path={routes.orders}
          element={
            <PrivateRoute>
              <AppLayout>
                <Orders />
              </AppLayout>
            </PrivateRoute>
          }
        />

        <Route 
          path={routes.particularRestaurant}
          element={
            <PrivateRoute>
              <AppLayout>
                <ParticularRestaurant />
              </AppLayout>
            </PrivateRoute>
          }
        />
      </Routes>
    </>
  );
};

export default MainLayout;
