import { Box, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react';

const NotificationDialog: React.FC<any> = ({ handleClose, open }) => {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            hideBackdrop={true}
            PaperProps={{ style: { position: 'absolute', top: '5%', right: '0', borderRadius: '12px', minHeight: "300px" } }}
        >
            <DialogTitle>
                <Box>
                    <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>Notifications</Typography>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box sx={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    You don't have any notifications right now.
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default NotificationDialog
