import { Box, Grid, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ReactChildren } from "../../interfaces/Common";
import Image from "../../Assets/assetHelper"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: "100vh",
    minHeight: "100vh",
    width: "100vw",
    position: "relative",
    backgroundColor: theme.palette.KenColors.ClientGrey2
  },
  formContainer: {
    position: "fixed",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 2,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100% !important",
    minHeight: "100vh !important",
    width: "100% !important",
    alignItems: "center", 
    [theme.breakpoints.down("xs")]: {
      top: "40%",
      left: "10%",
      transform: "translate(-40%, -40%)",
    },
  },
  copywrite: {
    fontSize: "14px !important",
    fontWeight: "500",
    color: "#8A8A8A"
  }
}));

const AuthLayout: React.FunctionComponent<ReactChildren> = ({ children }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.container}>
      <Box sx={{ position: "fixed", bottom: "0", left: "0" }}>
        {/* <img src={Image.Images.investorLoginVector} alt="" style={{ opacity: 0.1 }} /> */}
      </Box>
      <Grid item xs={12}>
        <Box className={classes.formContainer}>{children}</Box>
      </Grid>
      <Box sx={{ position: "fixed", bottom: "5%", left: "50%", transform: "translate(-50%, -50%)", }}>
        <Typography className={classes.copywrite}>© 2024 ZIPZAP</Typography>
      </Box>
    </Grid>
  );
};

export default AuthLayout;
