import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import LogoutIcon from '@mui/icons-material/Logout';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import { Box, Divider, Grid, IconButton, ListItemButton, ListItemIcon, ListItemText, Paper, Tooltip, Typography, useMediaQuery } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Image from '../../Assets/assetHelper';
import KenAvatar from '../../GlobalComponents/KenAvatar';
import { AuthContext } from '../../Helpers/Contexts/authContext';
import KenColors from '../../Helpers/Themes/kenColors';
import routes from '../../Utils/routes';
import { ReactChildren } from '../../interfaces/Common';
import { AuthUser } from '../../interfaces/Contexts';
import AppBreadCrumbs from '../BreadCrumbs';
import NotificationDialog from './NotificationDialog';
import SideNavTabs from './Tabs';
import { useStyles } from './styles';
import LOGOIMAGE from '../../Assets/logo123.png'

const AppLayout: React.FunctionComponent<ReactChildren> = ({ children }) => {
  const classes = useStyles();
  const { dispatch } = useContext(AuthContext);
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:1225px)');
  const [hideSideBar, setHideSideBar] = useState(false);
  const [openNotification, setOpenNotification] = React.useState(false);

  const handleOpenClickOpenNotification = () => {
    setOpenNotification(true);
  };

  const handleCloseNotificationDialog = () => {
    setOpenNotification(false);
  };

  const userData = JSON.parse(`${localStorage.getItem('user')}`) as AuthUser;
  const handleListClick = (path: string, index: number) => {
    navigate(path);
  };

  useEffect(() => {
    setHideSideBar(isMobile);
  }, [isMobile]);

  return (
    <>
      <Box
        sx={{
          height: '100vh',
          width: '100vw',
          backgroundColor: '#F6F8FB',
        }}
      >
        <Grid container className={classes.mainContainer}>
          <Grid
            item
            xs={hideSideBar ? 2 : 2}
            sm={hideSideBar ? 1 : 2}
            md={hideSideBar ? 0.5 : 2}
            xl={hideSideBar ? 0.4 : 2}
            className={`sidebar scrollbar-cus ${hideSideBar ? 'sidebar-collapsed' : 'sidebar-expanded'}`}
          >
            <Paper className={`${classes.sideBarContainer} scrollbar-cus`}>
              <Box className={classes.logoContainer}>
                {!hideSideBar && <img src={LOGOIMAGE} alt="logo" className={classes.logo} onClick={() => navigate(routes.restaurant)} />}
                <Box>
                  <IconButton
                    onClick={() => {
                      if (!isMobile) {
                        setHideSideBar(!hideSideBar);
                      }
                    }}
                  >
                    {hideSideBar ? <KeyboardDoubleArrowRightIcon className={classes.sideBarIcon} /> : <KeyboardDoubleArrowLeftIcon className={classes.sideBarIcon} />}
                  </IconButton>
                </Box>
              </Box>
              <Box className={`${classes.sideBarSubContainer} scrollbar-cus`}>
                <Box className={`${classes.sideBarSubContainer1} scrollbar-cus`}>
                  <SideNavTabs handleListClick={handleListClick} hideSideBar={hideSideBar} />
                </Box>
                <Box sx={{ mb: '24px' }}>
                  <ListItemButton
                    className={classes.listItemButton}
                    onClick={() => {
                      dispatch({ type: 'LOGOUT' });
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: { xs: '2px', lg: '40px' } }}>
                      <LogoutIcon className={classes.listItemIcon} />
                    </ListItemIcon>
                    {!hideSideBar && (
                      <ListItemText
                        className={classes.listItemText}
                        sx={{
                          display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' },
                        }}
                      >
                        Logout
                      </ListItemText>
                    )}
                  </ListItemButton>
                </Box>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={hideSideBar ? 10 : 10} sm={hideSideBar ? 11 : 10} md={hideSideBar ? 11.5 : 10} xl={hideSideBar ? 11.6 : 10} className={`sidebar`}>
            <Box className={classes.headerContainer}>
              <Box>
                <Paper className={classes.header} sx={{ borderRadius: '16px !important' }}>
                  {hideSideBar && <img src={Image.Images.turbostartWhiteLogo} alt="logo" className={classes.logo} onClick={() => navigate(routes.restaurant)} />}
                  <Box>
                    <AppBreadCrumbs />
                  </Box>
                </Paper>
              </Box>
              <Box>
                <Paper className={classes.actions}>
                  <Tooltip title="Notifications">
                    <IconButton sx={{ marginRight: { xs: '1px', lg: '10px' } }} onClick={handleOpenClickOpenNotification}>
                      <NotificationsNoneOutlinedIcon sx={{ fontSize: { xs: '20px', lg: '36px' } }} />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Profile">
                    <IconButton sx={{ p: '0' }}>
                      <KenAvatar classes={classes.avatar} src={Image.Images.DefaultProfileImage} />
                    </IconButton>
                  </Tooltip>
                  <Typography className={classes.actionText}>{userData.applicantName}</Typography>
                </Paper>
              </Box>
            </Box>
            <Divider sx={{ mx: '20px', color: KenColors.ClientRoyalpurple50 }} />
            <Box className={`${classes.childrenContainer} scrollbar-hide`}>{children}</Box>
          </Grid>
        </Grid>
      </Box>
      {openNotification && <NotificationDialog open={openNotification} handleClose={handleCloseNotificationDialog} />}
    </>
  );
};

export default AppLayout;
