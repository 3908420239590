import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    mainContainer: {
      height: "100%",
      width: "100%",
      overflow: "hidden"
    },
    sideBarContainer: {
      background: `linear-gradient(to bottom, #D32F2F, #7B1FA2)`,
      height: "100%",
      width: "100%",
      overflowX: "hidden",
      overflowY: "auto",
      boxShadow: "none !important",
      borderRadius: "0px !important",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between"
    },
    logoContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center !important",
      justifyContent: "space-between",
      paddingTop: "20px !important",
      padding: "15px !important",
      cursor: "pointer"
    },
    logo: {
      height: "38px",
      cursor: "pointer",
      [theme.breakpoints.down("sm")]:{
        height: "18px",
      }
    },
    sideBarIcon: {
      height: "18px",
      color: theme.palette.KenColors.kenWhite,
      [theme.breakpoints.down("md")]: {
        height: "14px",
      }
    },

    // tabs
    listItemIconActive: {
      color: theme.palette.KenColors.kenWhite,
    },
    listItemIcon: {
      color: theme.palette.KenColors.kenWhite,
    },
    listItemButtonActive: {
      background: `linear-gradient(90deg, ${theme.palette.KenColors.ClientRoyalpurple400}, #B12412) !important`,
      borderLeft: "4px solid #E6E6E6 !important",
    },
    nestedlistItemButtonActive: {
      background: `rgba(247, 236, 242, 0.10) !important`,
    },
    listItemButton: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      paddingInline: "20px !important",
      [theme.breakpoints.down("lg")]: {
        paddingInline: "18px !important",
      },
      [theme.breakpoints.down("md")]: {
        paddingInline: "16px !important",
      },
      [theme.breakpoints.down("sm")]: {
        paddingInline: "14px !important",
      }
    },
    listItemTextActive: {
      color: `${theme.palette.KenColors.kenWhite} !important`,
    },
    listItemText: {
      color: `${theme.palette.KenColors.clientDarkclientDark} !important`,
      fontSize: '16px !important',
      fontWeight: '500 !important',
      lineHeight: "22px !important",
      [theme.breakpoints.down("lg")]: {
        fontSize: '14px !important',
      },
      [theme.breakpoints.down("md")]: {
        fontSize: '12px !important',
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: '10px !important',
      }
    },

    // header
    headerContainer: {
      height: "80px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      paddingInline: "5px"
    },
    header: {
      background: `#F6F8FB !important`,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      gap: "30px",
      height: '70px !important',
      paddingInline: '20px',
      textAlign: 'start',
      boxShadow: 'none !important',
      [theme.breakpoints.down('md')]: {
        height: '50px !important',
      }
    },
    actions: {
      backgroundColor: `inherit !important`,
      display: 'flex',
      justifyContent: 'center',
      paddingInline: '10px',
      alignItems: 'center',
      borderRadius: '16px !important',
      gap: "10px",
      marginRight: "5px",
      boxShadow: 'none !important',
      [theme.breakpoints.down('md')]: {
        paddingInline: '5px',
        gap: "2px",
        marginRight: "1px",
      },
    },
    avatar: {
      width: '48px !important',
      height: '48px !important',
      color: theme.palette.KenColors.ClientBlue7,
      backgroundColor: theme.palette.KenColors.WhiteGray4,
      [theme.breakpoints.down("sm")]: {
        width: '28px !important',
        height: '28px !important',
      }
    },
    actionText: {
      whiteSpace: 'wrap',
      fontSize: '16px !important',
      fontWeight: '500 !important',
      color: theme.palette.KenColors.ClientBlack,
      [theme.breakpoints.down("lg")]: {
        fontSize: '14px !important',
      },
      [theme.breakpoints.down("md")]: {
        fontSize: '12px !important',
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: '10px !important',
      }
    },

    // children
    childrenContainer: {
      height: `calc(100vh - 80px) !important`,
      width: "100% !important",
      overflow: "auto !important",
      padding: '8px'
    },

    // side bar
    sideBarSubContainer: {
      height: "100% !important",
      display: "flex",
      flexDirection: "column",
      flex: "1",
      overflowX: "hidden",
      overflowY: "auto",
    },
    sideBarSubContainer1: {
      flex: "1",
      overflowX: "hidden",
      overflowY: "auto",
    }
  };
});
