const Images = {
  Raise: 'https://inazstgpfs3001.blob.core.windows.net/assets/Ken42%20Alumni/PNG/Group%2048099673.png',
  Education: 'https://inazstgpfs3001.blob.core.windows.net/assets/Ken42%20Alumni/PNG/education.png',
  Medal: 'https://inazstgpfs3001.blob.core.windows.net/assets/Ken42%20Alumni/PNG/medal-star.png',
  Credit: 'https://inazstgpfs3001.blob.core.windows.net/assets/Ken42%20Alumni/PNG/two_credit_cart.png',
  DefaultProfileImage: 'https://flamestorageken42.blob.core.windows.net/flame-storage-ken42/6929820272949005-WhatsApp Image 2024-07-24 at 19.39.22.jpeg1722363553109',
  DefaultOrganizationLogo: 'https://inazstgpfs3001.blob.core.windows.net/assets/Kenverse/Alumni/Images/ken42(png).png',
  AuthLayoutBg: 'https://inazstgpfs3001.blob.core.windows.net/assets/Kenverse/Alumni/Images/LoginBackground.png',
  NoDataTheme: 'https://alumnikenverse.blob.core.windows.net/images/no-event-investors.png',
  PostEventBanner: 'https://alumnikenverse.blob.core.windows.net/images/investor-plant.png',
  PostJobBanner: 'https://inazstgpfs3001.blob.core.windows.net/assets/Kenverse/Alumni/Images/PostJob.png',
  EventCardCoverImage: 'https://inazstgpfs3001.blob.core.windows.net/assets/Kenverse/Alumni/Images/noimage.jpg',
  OnBoardBg: 'https://inazstgpfs3001.blob.core.windows.net/assets/Kenverse/Alumni/Images/backgroundonboard.png',
  Complain: 'https://inazstgpfs3001.blob.core.windows.net/assets/Ken42%20Alumni/PNG/Objects.png',
  BeginSurvey: 'https://alumnikenverse.blob.core.windows.net/images/survey.png',
  CreateSurvey: 'https://alumnikenverse.blob.core.windows.net/images/create-survey.png',
  SurveyThankYou: 'https://alumnikenverse.blob.core.windows.net/images/survey-thankyou.png',
  entreprenursOfKen42: 'https://alumnikenverse.blob.core.windows.net/images/pioneers of ken42.png',
  investorLoginVector: "https://alumnikenverse.blob.core.windows.net/images/Vector.png",
  turbostartInvestor: "https://alumnikenverse.blob.core.windows.net/images/turbostart-investor.png",
  turbostartWhiteLogo: "https://flamestorageken42.blob.core.windows.net/flame-storage-ken42/15261156061462544-transparent_logo.png1722365411478",
  turbostartLogo: "https://flamestorageken42.blob.core.windows.net/flame-storage-ken42/15261156061462544-transparent_logo.png1722365411478",
  investorComplaint: "https://alumnikenverse.blob.core.windows.net/images/investor-complaint.png",
  investorRocket: "https://alumnikenverse.blob.core.windows.net/images/Rocket.png",
  defaultImage: "https://alumnikenverse.blob.core.windows.net/images/default-image.jpg",
  portfolioDetail: "https://alumnikenverse.blob.core.windows.net/images/portfolioDetail.png"
};
const Svg = {};
const Icons = {
  Error: 'https://inazstgpfs3001.blob.core.windows.net/assets/Kenverse/Alumni/Images/error.png',
  DefaultServiceIcon: 'https://inazstgpfs3001.blob.core.windows.net/assets/Ken42%20Alumni/PNG/education.png',
  Location: 'https://alumnikenverse.blob.core.windows.net/images/Group.png',
  Designation: 'https://alumnikenverse.blob.core.windows.net/images/designation.png',
  Like: 'https://alumnikenverse.blob.core.windows.net/images/like.png',
  Share: 'https://alumnikenverse.blob.core.windows.net/images/share.png',
  Comment: 'https://alumnikenverse.blob.core.windows.net/images/comment.png',
  ArrowForward: 'https://alumnikenverse.blob.core.windows.net/images/arrowForward.png',
  Check: 'https://alumnikenverse.blob.core.windows.net/images/Check.png',
  Profile: 'https://alumnikenverse.blob.core.windows.net/images/profile.png',
  editFileIcon: "https://alumnikenverse.blob.core.windows.net/images/editFileIcon.png",
  leafIcon: "https://alumnikenverse.blob.core.windows.net/images/leafIcon.png",
  websplitIcon: "https://alumnikenverse.blob.core.windows.net/images/websplitIcon.png",
  linkedinIcon: "https://alumnikenverse.blob.core.windows.net/images/linkedin-icon.png",
  userClientIcon: "https://alumnikenverse.blob.core.windows.net/images/users.png",
  awardIcon: "https://alumnikenverse.blob.core.windows.net/images/support.png",
  linkedinIconBlue: "https://alumnikenverse.blob.core.windows.net/images/linkedin-icon-blue.png",
  globe: "https://alumnikenverse.blob.core.windows.net/images/ph_globe.png",
  india: "https://alumnikenverse.blob.core.windows.net/images/indiaflag.png",
  rocket: "https://alumnikenverse.blob.core.windows.net/images/general.png",
  graph: "https://alumnikenverse.blob.core.windows.net/images/business.png"
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { Images, Icons, Svg };
