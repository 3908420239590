import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import routes from '../../../Utils/routes';
import { useStyles } from '../styles';

interface SideNavProps {
  handleListClick: (link: string, index: number) => void;
  hideSideBar: boolean;
}

const SideNavTabs: React.FunctionComponent<SideNavProps> = ({ handleListClick, hideSideBar }) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const [openPeople, setOpenPeople] = useState(false);

 
  const sideNavContents = [
    {
      icon: (isActive: boolean) => <HomeOutlinedIcon className={isActive ? classes.listItemIconActive : classes.listItemIcon} />,
      label: 'Restuarant',
      link: routes.restaurant,
      name: 'home',
    },
    {
      icon: (isActive: boolean) => <AnalyticsOutlinedIcon className={isActive ? classes.listItemIconActive : classes.listItemIcon} />,
      label: 'Users',
      link: routes.users,
      name: 'portfolio',
    },
  ];

  const isActive = (link: string) => pathname.startsWith(link);

  return (
    <>
      {sideNavContents.map((items, index) => {
        return (
          <ListItemButton
            key={index}
            onClick={() => {
              handleListClick(items.link, index);
            }}
            sx={{ marginTop: index === 0 ? { xs: '10px', sm: '15px', md: '25px', lg: '35px' } : '12px' }}
            className={`${classes.listItemButton} ${isActive(items.link) && classes.listItemButtonActive}`}
          >
            <ListItemIcon sx={{ minWidth: '40px' }}>{items.icon(isActive(items.link))}</ListItemIcon>
            {!hideSideBar && (
              <ListItemText
                className={`${classes.listItemText} ${isActive(items.link) && classes.listItemTextActive}`}
                sx={{
                  display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' },
                }}
              >
                {items.label}
              </ListItemText>
            )}
          </ListItemButton>
        );
      })}
    </>
  );
};

export default SideNavTabs;
